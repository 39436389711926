import { LegalPageArticle } from 'libs/brand-system/src/templates/LegalPageArticle';
import { graphql } from 'gatsby';
import React from 'react';
import Layout from '../../layout';
import { HeadWrapper } from '../../layout/HeadWrapper';

export const Head = ({ data, pageContext, location }: any) => (
  <HeadWrapper
    data={data}
    pageContext={pageContext}
    location={location}
    templateName="LegalTextPage"
    dataName={'datoCmsLegalText'}
    largestContentfullPaintImg={null}
  />
);

export const query = graphql`
  query DatoLegalText($locale: String, $id: String) {
    datoCmsLegalText(locale: $locale, id: { eq: $id }) {
      ...LegalTextPage
    }
  }
`;

const AllLegalTextPage = ({ data, pageContext }: any) => {
  const doc = data?.datoCmsLegalText;

  if (!doc?.content[0]) return null;
  const { blocks, medias, title, lastUpdate } = doc.content[0];

  return (
    <Layout
      pageContext={pageContext}
      data={doc}
      tracking={{ pageType: 'legal' }}
      templateName="LegalPage"
    >
      <div className="container py-[96px] relative">
        <LegalPageArticle
          blocks={blocks}
          medias={medias}
          title={title}
          lastUpdate={lastUpdate}
          locale={pageContext?.locale}
        />
      </div>
    </Layout>
  );
};

export default AllLegalTextPage;
